<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card">
          <dx-data-grid
              v-if="true"
              id="customerBalanceGrid"
              ref="customerBalanceGridRef"
              :data-source="CustomerBalanceDataSource"
              :height="setHeight"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :hover-state-enabled="true"
              :row-alternation-enabled="true"
              :repaint-changes-only="true"
              @initialized="onInitialized"
              @content-ready="onContentReady"
              @toolbar-preparing="onToolbarPreparing($event)"
              @cell-prepared="onCellPrepared($event)"
            >
            <dx-selection
              show-check-boxes-mode="always"
              select-all-mode="page"
              :allow-select-all="true"
              :width="10"
              mode="multiple"
            />
            <dx-column data-field="creationTime" caption="Created At" data-type="datetime" :format="orderDateFormat" />
            <dx-column data-field="originalTransactionAmount" caption="Transaction Amount" alignment="right" cell-template="transactionAmountCell" />
            <template #transactionAmountCell="{ data }">
                {{ getTransactionFormat(data) }}
            </template>
            <dx-column data-field="transactionFees" cell-template="creditCell" />
            <dx-column data-field="credit" caption="Balance" cell-template="creditCell" />
            <template #creditCell="{ data }">
                {{ getCurrencyFormat(data.value) }}
            </template>
            <dx-column data-field="transactionType" :allow-sorting="false" cell-template="transactionTypeCell" width="180" />
            <template #transactionTypeCell="{ data }">
              <span class="d-block badge" :class="getTransactionTypeFormat(data.value)">
                {{ data.value }}
              </span>
            </template>
            <dx-column data-field="balanceType" caption="Category" />
            <dx-column data-field="notes" caption="Notes" />
            <dx-scrolling column-rendering-mode="standard" />

            <!-- <dx-search-panel
              :highlight-search-text="true"
              :search-visible-columns-only="true"
              :visible="true"
              :width="300"
              placeholder="Search..."
            /> -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :sorting="true" :filtering="true" />
            <template #toolbarFilters>
                <div class="d-flex flex-row align-items-center">
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedDateRange"
                      :data-source="dateRangesList"
                      :drop-down-options="{width: '180px'}"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="setDateRanges"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedBalanceType"
                      :data-source="balanceTypes"
                      :drop-down-options="{width: '180px'}"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                  <div v-show="isCustomDateRange" class="mr-1">
                    <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
                  </div>
                  <div v-show="isCustomDateRange" class="mr-1">
                    <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
                  </div>
                  <div v-if="isOwner" class="mr-half">
                    <deposit-widget @on-emit-deposit-change="reload" />
                  </div>
                  <div class="mr-half">
                    <current-credit-widget />
                  </div>
                </div>
              </template>
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { Notify } from '@robustshell/utils/index'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import CustomerBalanceFilter from '@/http/models/search-filters/CustomerBalanceFilter'
import BalanceTypesEnum, { getBalanceTypeList } from '@/enums/balanceType.enum.js'
import Filters from '@robustshell/mixins/filters'
import { currencyFormatter, formatDate } from '@core/utils/filter'
import useDateRanges from '@/@core/composables/useDateRanges'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import DepositWidget from '@/views/apps/finance/balances/DepositWidget.vue'
import CurrentCreditWidget from '@/views/apps/finance/balances/CurrentCreditWidget.vue'
import useCurrentUser from '@/libs/app/current-user'
import customerBalanceService from '@/http/requests/finance/customerBalanceService'
import { CustomerBalanceDataSource } from './customerBalanceStore'

export default {
  components: {
    'deposit-widget': DepositWidget,
    'current-credit-widget': CurrentCreditWidget,
  },
  mixins: [GridBase, Filters],
  data() {
    const firstDayOfMonth = new Date()
    const plusOneDay = new Date()
    firstDayOfMonth.setDate(1)
    firstDayOfMonth.setHours(0, 0, 0, 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)
    return {
      CustomerBalanceDataSource,
      accountNo: '',
      balanceTypes: getBalanceTypeList(),
      beginDate: firstDayOfMonth,
      endDate: plusOneDay,
      selectedBalanceType: BalanceTypesEnum.ALL.value,
      selectedDateRange: predefinedDateRangesEnum.CURRENTMONTH.value,
      dateRangesList: getPredefinedDateRangesList(),
      filters: {
        query: '',
      },
      orderDateFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC',
      },
    }
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    const { userCompanyId, userRoleName } = useCurrentUser()
    return {
      setDateRanges, startDate, isCustomDateRange, userCompanyId, userRoleName,
    }
  },
  computed: {
    dataGrid() {
      return this.$refs.customerBalanceGridRef.instance
    },
    hasAccountReadPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    isOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER' || this.userRoleName === 'IOWNER'
    },
  },
  watch: {
    startDate(newValue) {
      this.beginDate = newValue
    },
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    getTransactionTypeFormat(transactionType) {
      if (transactionType === undefined) return 'badge-light-danger'
      if (transactionType === 'DEBIT') return 'badge-warning'
      if (transactionType === 'CREDIT') return 'badge-success'
      return 'badge-primary'
    },
    getTransactionFormat(data) {
      return data.data.transactionType === 'CREDIT' ? currencyFormatter(data.value) : `(${currencyFormatter(data.value)})`
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Balances',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Balances',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadBalances()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedBalances()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
    },
    async onDownloadBalances(e) {
      const filters = CustomerBalanceFilter.getFilters()
      const pageableQuery = 'page=0&size=2000'
      const response = await customerBalanceService.fetchBalancesByType(pageableQuery, filters)
      const data = response.data.body.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedBalances() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      if (!data) {
        Notify.warning('Missing data to download')
        return
      }
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Customer Balance ${fileDate}`)
      worksheet.columns = [
        { header: 'Creation Time', key: 'creationTime', width: 25 },
        { header: 'Transaction Amount', key: 'originalTransactionAmount', width: 15 },
        { header: 'Transaction Fees', key: 'transactionFees', width: 15 },
        { header: 'Balance', key: 'credit', width: 15 },
        { header: 'Transaction Type', key: 'transactionTypeCell', width: 15 },
        { header: 'Category', key: 'balanceType', width: 15 },
        { header: 'Notes', key: 'notes', width: 100 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:G1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          formatDate(item.creationTime),
          item.originalTransactionAmount,
          item.transactionFees,
          item.credit,
          item.transactionType,
          item.balanceType,
          item.notes,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Customer Balances ${fileDate}.xlsx`,
        )
      })
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.data.transactionType === 'DEBIT') {
          if (e.column.dataField === 'originalTransactionAmount') {
            e.cellElement.style.cssText = 'color: #f76552; text-align: right;'
          }
        }
      }
    },
    searchByFilter() {
      CustomerBalanceFilter.companyId = this.userCompanyId
      CustomerBalanceFilter.accountNo = this.accountNo
      CustomerBalanceFilter.q = this.filters.query
      CustomerBalanceFilter.balanceType = this.selectedBalanceType
      CustomerBalanceFilter.beginDate = this.beginDate
      CustomerBalanceFilter.endDate = this.endDate
      const filters = CustomerBalanceFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    initialLoad() {
      CustomerBalanceFilter.setDefaultFilters()
      CustomerBalanceFilter.companyId = this.userCompanyId
      CustomerBalanceFilter.beginDate = this.oneMonthAgo
      CustomerBalanceFilter.endDate = this.plusOneDay
      const filters = CustomerBalanceFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      this.accountNo = ''
      this.selectedBalanceType = BalanceTypesEnum.SHIPPING.value
      this.filters.query = ''
      CustomerBalanceFilter.companyId = this.userCompanyId
      CustomerBalanceFilter.beginDate = this.oneMonthAgo
      CustomerBalanceFilter.endDate = this.plusOneDay
      CustomerBalanceFilter.accountNo = this.accountNo
      CustomerBalanceFilter.q = this.filters.query
      CustomerBalanceFilter.balanceType = this.selectedBalanceType
      const filters = CustomerBalanceFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      this.dataGrid.clearFilter('search')
      dataSource.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
