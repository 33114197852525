/* eslint-disable lines-between-class-members */
import { BalanceTypesEnum } from '@/enums'
import BaseSearchFilter from './BaseSearchFilter'

export class CustomerBalanceFilter extends BaseSearchFilter {
  /** @type {string} */
  #balanceType = ''
  constructor() {
    super()
    this.#balanceType = this.balanceType
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.balanceType = BalanceTypesEnum.ALL.value
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      balanceType: this.balanceType,
    }
    return filter
  }

  /** @returns {string} */
  get balanceType() {
    return this.#balanceType
  }
  /** @param {string} value */
  set balanceType(value) {
    this.#balanceType = value
  }
}
export default new CustomerBalanceFilter()
